<template>
    <b-container fluid>
        <b-row>
            <b-col md="12">
                <iq-card>
                    <template v-slot:headerTitle>
                        <h4 class="card-title">{{$t('external_sidebar.tr_id_card')}}</h4>
                    </template>
                    <template v-slot:body>
                        <b-overlay :show="loadingState">
                            <b-row>
                                <b-col md="12" class="table-responsive">
                                    <b-table bordered hover :items="listData" :fields="columns" aria-hidden="loading | listReload ? 'true' : null" :emptyText="$t('globalTrans.noDataFound')" show-empty>
                                      <template v-slot:cell(index)="data">
                                      {{ $n(data.index + pagination.slOffset) }}
                                      </template>
                                      <template v-slot:cell(action)="data">
                                        <b-button v-b-modal.modal-4 variant=" iq-bg-primary mr-1 mb-1" size="sm" @click="edit(data.item)"><i class="fas fa-print m-0"></i></b-button>
                                      </template>
                                    </b-table>
                                    <b-pagination
                                        v-model="pagination.currentPage"
                                        :per-page="pagination.perPage"
                                        :total-rows="pagination.totalRows"
                                        @input="searchData"
                                        />
                                </b-col>
                            </b-row>
                        </b-overlay>
                    </template>
                </iq-card>
            </b-col>
        </b-row>
        <b-modal id="modal-4" size="lg" :title="formTitle" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
            <Form :id="editItemId" :key="editItemId"/>
        </b-modal>

    </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import Form from './Form'
import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'
import { traineeIdCard } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'

export default {
    mixins: [ModalBaseMasterList],
    components: {
      Form
    },
    data () {
      return {
        search: {
          circular_memo_no: '',
          training_category_id: 0,
          training_type_id: 0,
          training_title_id: 0,
          org_id: this.$store.state.ExternalUserIrrigation.panelObj.trainingOrg
        },
        trainingCategoryList: [],
        trainingTitleList: []
      }
    },
    computed: {
      allBatchList () {
        return [
          { value: 1, text: this.$i18n.locale === 'en' ? '1st' : '১ম', text_en: '1st', text_bn: '১ম' },
          { value: 2, text: this.$i18n.locale === 'en' ? '2nd' : '২য়', text_en: '2nd', text_bn: '২য়' },
          { value: 3, text: this.$i18n.locale === 'en' ? '3rd' : '৩য়', text_en: '3rd', text_bn: '৩য়' },
          { value: 4, text: this.$i18n.locale === 'en' ? '4th' : '৪র্থ', text_en: '4th', text_bn: '৪র্থ' },
          { value: 5, text: this.$i18n.locale === 'en' ? '5th' : '৫ম', text_en: '5th', text_bn: '৫ম' }
        ]
      },
      trainingTypeList: function () {
        return this.$store.state.ExternalUserTraining.traineeTranerObj.trainingTypeList.filter(item => item.status === 1)
      },
      orgList: function () {
        return this.$store.state.ExternalUserIrrigation.commonObj.organizationProfileList.filter(item => item.status === 0)
      },
      formTitle () {
        return this.$t('external_sidebar.tr_id_card')
      },
      columns () {
        const labels = [
          { label: this.$t('globalTrans.sl_no'), class: 'text-left' },
          { label: this.$t('elearning_config.organization'), class: 'text-left' },
          // { label: this.$t('globalTrans.office'), class: 'text-left' },
          { label: this.$t('elearning_config.training_type'), class: 'text-left' },
          { label: this.$t('elearning_config.training_title'), class: 'text-left' },
          { label: this.$t('elearning_iabm.batch_no'), class: 'text-left' },
          { label: this.$t('elearning_iabm.circular_memo_no'), class: 'text-left' },
          { label: this.$t('globalTrans.print'), class: 'text-center' }
        ]

        let keys = []

        if (this.$i18n.locale === 'bn') {
            keys = [
            { key: 'index' },
            { key: 'org_bn' },
            // { key: 'office_bn' },
            { key: 'training_type_bn' },
            { key: 'training_title_bn' },
            { key: 'batch_name_bn' },
            { key: 'circular_memo_no' },
            { key: 'action' }
            ]
        } else {
            keys = [
            { key: 'index' },
            { key: 'org' },
            // { key: 'office' },
            { key: 'training_type' },
            { key: 'training_title' },
            { key: 'batch_name' },
            { key: 'circular_memo_no' },
            { key: 'action' }
            ]
        }

        return labels.map((item, index) => {
            return Object.assign(item, keys[index])
        })
      }
    },
    watch: {
      'search.training_type_id': function (newValue) {
        this.trainingCategoryList = this.getCategoryList(newValue)
      },
      'search.training_category_id': function (newValue) {
        this.trainingTitleList = this.getTrainingTitleList(newValue)
      }
    },
    async created () {
      this.loadData()
    },
    mounted () {
      core.index()
    },
    methods: {
      async searchData () {
        this.loadData()
      },
      getCategoryList (typeId) {
        const trainingCategoryList = this.$store.state.ExternalUserTraining.traineeTranerObj.trainingCategoryList.filter(item => item.status === 1)
        if (typeId) {
          return trainingCategoryList.filter(item => item.training_type_id === typeId)
        }
        return trainingCategoryList
      },
      getTrainingTitleList (categoryId) {
        const trainingTitleList = this.$store.state.ExternalUserTraining.traineeTranerObj.trainingTitleList.filter(item => item.status === 1)
        if (categoryId) {
          return trainingTitleList.filter(item => item.training_category_id === categoryId)
        }
        return trainingTitleList
      },
      loadData () {
        this.search.registration_for = this.$store.state.ExternalUserIrrigation.panelObj.trainingType
        const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
        this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
        RestApi.getData(trainingElearningServiceBaseUrl, traineeIdCard, params).then(response => {
          if (response.success) {
              this.$store.dispatch('setList', this.getCustomDataList(response.data.data))
              this.paginationData(response.data)
          }
          this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
        })
      },
      getCustomDataList (data) {
        const listData = data.map(item => {
          const orgObj = this.$store.state.ExternalUserIrrigation.commonObj.organizationProfileList.find(doc => doc.value === parseInt(item.org_id))
          const orgReportHeadObj = this.$store.state.ExternalUserTraining.traineeTranerObj.trainingReportHeadList.find(doc => doc.org_id === parseInt(item.org_id))
          const orgData = {}
          if (typeof orgObj !== 'undefined') {
            orgData.org = orgObj.text_en
            orgData.org_bn = orgObj.text_bn
            orgData.org_address = orgObj.address
            orgData.org_address_bn = orgObj.address_bn
            orgData.right_logo = orgReportHeadObj.right_logo
          } else {
            orgData.org = ''
            orgData.org_bn = ''
            orgData.org_address = ''
            orgData.org_address_bn = ''
            orgData.right_logo = ''
          }
          const userOrgObj = this.$store.state.ExternalUserIrrigation.commonObj.organizationProfileList.find(doc => doc.value === parseInt(item.user_org_id))
          const userOrgData = {}
          if (typeof userOrgObj !== 'undefined') {
            userOrgData.user_org = userOrgObj.text_en
            userOrgData.user_org_bn = userOrgObj.text_bn
            userOrgData.user_org_abbreviation = userOrgObj.abbreviation
            userOrgData.user_org_abbreviation_bn = userOrgObj.abbreviation_bn
            userOrgData.user_org_address = userOrgObj.address
            userOrgData.user_org_address_bn = userOrgObj.address_bn
          } else {
            userOrgData.user_org = ''
            userOrgData.user_org_bn = ''
            userOrgData.user_org_abbreviation = ''
            userOrgData.user_org_abbreviation_bn = ''
            userOrgData.user_org_address = ''
            userOrgData.user_org_address_bn = ''
          }
          const officeObj = this.$store.state.ExternalUserIrrigation.commonObj.officeList.find(office => office.value === parseInt(item.professional_office_id))
          const officeData = {}
          if (typeof officeObj !== 'undefined') {
            officeData.office = officeObj.text_en
            officeData.office_bn = officeObj.text_bn
            officeData.address = officeObj.address
            officeData.address_bn = officeObj.address_bn
          } else {
            officeData.office = ''
            officeData.office_bn = ''
            officeData.address = ''
            officeData.address_bn = ''
          }
          const trainingTypeListObj = this.$store.state.ExternalUserTraining.traineeTranerObj.trainingTypeList.find(obj => obj.value === item.training_type_id)
          const trainingTypeData = {
            training_type: trainingTypeListObj?.text_en,
            training_type_bn: trainingTypeListObj?.text_bn
          }
          const trainingTitleListObj = this.$store.state.ExternalUserTraining.traineeTranerObj.trainingTitleList.find(obj => obj.value === item.training_title_id)
          const trainingTitleData = {
            training_title: trainingTitleListObj?.text_en,
            training_title_bn: trainingTitleListObj?.text_bn
          }
          const batchObj = this.allBatchList.find(batch => batch.value === parseInt(item.batch_no))
          const batchData = {}
          if (typeof batchObj !== 'undefined') {
            batchData.batch_name = batchObj.text_en
            batchData.batch_name_bn = batchObj.text_bn
          } else {
            batchData.batch_name = ''
            batchData.batch_name_bn = ''
          }
          return Object.assign({}, item, orgData, officeData, trainingTypeData, trainingTitleData, userOrgData, batchData)
        })
        return listData
      }
    }
}
</script>
